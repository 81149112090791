import { render, staticRenderFns } from "./referral-form-page.vue?vue&type=template&id=535a7b1f&scoped=true&"
import script from "./referral-form-page.vue?vue&type=script&lang=js&"
export * from "./referral-form-page.vue?vue&type=script&lang=js&"
import style0 from "./referral-form-page.vue?vue&type=style&index=0&id=535a7b1f&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "535a7b1f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KAutocomplete from '@web/components/core/k-autocomplete'
import KButton from '@web/components/core/k-button'
import KContainer from '@web/components/core/k-container'
import KGdpr from '@web/components/core/k-gdpr'
import KInput from '@web/components/core/k-input'
import KLayout from '@web/components/core/k-layout'
import KRadio from '@web/components/core/k-radio'
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {KAutocomplete,KButton,KContainer,KGdpr,KInput,KLayout,KRadio,VExpandTransition,VForm,VRadioGroup})
